import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import routes from "@/config/routes";

import "./config/normalize.less";
import "./config/normailzeAntdForm.less";
import reportWebVitals from "./reportWebVitals";
import "./config/i18n/configs";

// import VConsole from "vconsole";

const router = createBrowserRouter(routes);
// const vConsole = new VConsole();

// 引入lodash并注入到全局
window._ = require("lodash");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(<RouterProvider router={router} />);

reportWebVitals();
