import { useCallback, useRef} from 'react'
import {useNavigate} from 'react-router-dom'

// 判断当前是否目标路由
export const useIsTargetRoute = (routePath: string) => {
  return window.location.pathname.includes(routePath)
}

// 获取目标路由动态id
export const useGetRouteId = (routePath?: string) => {
  return window.location.pathname
         .match(new RegExp(`\/${routePath || '[\\w\-]+'}\/([0-9]+)`))
         ?.[1]
}

export const useGoToRoute = () => {
  const navigate = useCallback(useNavigate(), [])
  return (route: string) => { route && navigate(route) }
}

// 跳转到首页
export function useGoToCaHome () {
  const navigate = useRef(useNavigate())
  return {
    goToCaHome: () => { navigate.current('/') }
  }
}

// 跳转到登录页
export function useGoToCaLogin () {
  const navigate = useNavigate()
  return {
    goToCaLogin: () => { navigate('/auth/login') }
  }
}