import { UserDataProps } from "@/pages/CaMyPage/CaProFile";
import { request } from "../../request";

export const settings = {
    // 配信停止
    sendStopMessage: () => {
        return request({
            url: "/unsubscribe",
        });
    },

    // 配信再開
    sendOpenMessage: () => {
        return request({
            method: "post",
            url: "/resubscribe",
        });
    },

    // 获取用户基础信息
    getUserMessage: () => {
        return request({
            url: "/my/profile",
        });
    },

    // 获取用户资料卡
    getUserMessageCard: () => {
        return request({
            url: "/getProfileCard",
            responseType: "arraybuffer",
        });
    },

    // 下载用户资料卡
    downloadUserMessageCard: () => {
        return request({
            url: "/downloadGetProfileCard",
            headers: { responseType: "blob" },
        });
    },

    // 修改密码
    sendNewPassword: (data) => {
        return request({
            method: "put",
            url: "/resetPassword",
            data,
        });
    },

    // 退会
    postWithdrawal: (data) => {
        return request({
            method: "post",
            url: "/my/withdrawal",
            data,
            headers: { "Content-Type": "application/json" },
        });
    },

    // 修改用户资料
    putUserMessageCard: (data: UserDataProps) => {
        return request({
            method: "put",
            url: "/my/profile",
            data,
        });
    },

    // 内定先の業界
    getIndustryList: () => {
        return request({
            url: "/my/getIndustry",
        });
    },

    // 譲れない条件
    getNegotiateList: () => {
        return request({
            url: "/my/getNegotiate",
        });
    },

    // 理系の研究分野
    getScienceList: () => {
        return request({
            url: "/my/getScience",
        });
    },
};
